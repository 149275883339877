import React from "react"

import Layout from "../components/layout"
import AllPosts from "../components/all_posts"
import Seo from "../components/seo"

const BlogPage = () => (
  <Layout>
    <Seo title="Blog" description="All blog posts available on the website. Mainly covers web development, but also includes some personal posts as well." />
    <AllPosts />
  </Layout>
)

export default BlogPage
