import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
// import styles from "./post-list.module.scss"
import DateTime from "../components/datetime"

const AllPosts = () => {
  const data = useStaticQuery(graphql`
    query AllPosts {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {published: {eq: true}, fileAbsolutePath: {regex: "/blog/"}}) {
        group(field: frontmatter___category) {
          fieldValue
          edges {
            node {
              id
              excerpt
              html
              frontmatter {
                title
                htmldate: date
                year: date(formatString: "YYYY")
                date(formatString: "D MMM YYYY")
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <h1 className="font-semibold">All blog posts</h1>
        {data.allMarkdownRemark.group.map((group) =>(
          <section key={group.fieldValue}>
          <h2 className="{styles.category} text-3xl sm:text-2xl font-bold mb-1">{group.fieldValue}</h2>
            <ol className="{styles.container} list-none m-0 mb-8">
              {group.edges.map(({ node }) => (
                <li className="{styles.item} pl-0 mb-4 sm:mb-2" key={node.fields.slug}>
                  <article>
                    <Link to={node.fields.slug} className="{styles.link} block sm:flex sm:m-0 sm:justify-between sm:align-center">
                      <h3 className="{styles.title} text-xl sm:text-l">{node.frontmatter.title}</h3>
                      <DateTime key={node.id} datetime={node.frontmatter.htmldate} dateformatted={node.frontmatter.date} />
                    </Link>
                  </article>
                </li>
              ))}
            </ol>
          </section>
        ))}

    </>
  )
}

/*

{data.allMarkdownRemark.group.edges.map(({ node }) => (
          <li className={styles.item} key={node.id}>
            <article>
              <Link to={node.fields.slug} className={styles.link}>
                <h2 className={styles.title}>{node.frontmatter.title}</h2>
                <DateTime datetime={node.frontmatter.htmldate} dateformatted={node.frontmatter.date} />
              </Link>
            </article>
          </li>
        ))}
*/

export default AllPosts
